import React from 'react';
import "./AccessTypesComponent.scss";
import {Table,Button,Form} from 'react-bootstrap';
import {fetchData} from '../../services';
import AdminTableComponent from '../helpers/AdminTableComponent';
import LoaderComponent from '../../Loader/LoaderComponent';
const {REACT_APP_BACKEND_URL} = process.env;

export default class AccessTypesComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      userAccessTypes: [],
      page: 1,
      limit: 10,
      total: 0,
      orderBy: 'id',
      filter: '',
      isLoading: false,
    }
    this.fetchUserAccessTypes = this.fetchUserAccessTypes.bind(this);
    this.renderUserAccessTypes = this.renderUserAccessTypes.bind(this);
    this.updateOrderNumber = this.updateOrderNumber.bind(this);
    this.updateCriteria = this.updateCriteria.bind(this);
  }

  componentDidMount(){
    this.fetchUserAccessTypes();
  }

  fetchUserAccessTypes(){
    const {page,limit,orderBy,filter} = this.state;
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_URL}/api/user-access/types/${page}/${limit}/${orderBy}/${filter}`)
      .then(data=>this.setState({
        userAccessTypes:data.userAccessTypes,
        total:data.total,
      }))
      .catch(err=>console.warn(err))
      .finally(()=>this.setState({isLoading:false}));
  }

  removeUserAccessType(id){
    if(window.confirm("Czy usunąć?")){
      this.setState({isLoading:true});
      fetchData(`${REACT_APP_BACKEND_URL}/api/user-access/types/${id}`,'DELETE')
        .then(data=>this.fetchUserAccessTypes())
        .catch(err=>console.warn(err))
        .finally(()=>this.setState({isLoading:false}));
    }
  }

  updateOrderNumber(ev,uatId){
    const {target:{value}} = ev;
    const data = {'orderNumber':value};
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_URL}/api/user-access/types/${uatId}/update-on`,'PATCH',data)
      .then(data=>this.fetchUserAccessTypes())
      .catch(err=>console.warn(err.errorMessage))
      .finally(()=>this.setState({isLoading:false}));
  }

  updateCriteria(name,value){
    this.setState({
      [name]: value
    },()=>{
      this.fetchUserAccessTypes();
    })
  }

  renderSingleUserAccessType(uat,k){
    const {isLoading} = this.state;
    return (
      <>
        <td>
          {uat.id}
          <Button variant="danger" onClick={()=>this.removeUserAccessType(uat.id)}>Usuń</Button>
        </td>
        <td>
          <Form.Control
            type="number"
            onChange={(ev)=>this.updateOrderNumber(ev,uat.id)}
            value={uat.orderNumber || 1}
            disabled={isLoading}
          />
        </td>
        <td>{uat.name}</td>
        <td>{uat.days}</td>
        <td>{uat.cost} PLN</td>
        <td>{uat.packagesNumber}</td>
      </>
    )
  }

  renderUserAccessTypes(){
    const {page,total,limit,filter,isLoading} = this.state;
    const header = [
      (<span>Id</span>),
      (<span>Kolejność</span>),
      (<span>Nazwa</span>),
      (<span>Ide dni</span>),
      (<span>Koszt w PLN</span>),
      (<span>Ilość aktywnych pakietów</span>),
    ];
    const body = this.state.userAccessTypes.map((u,k) => this.renderSingleUserAccessType(u,k));
    const pagination = {
      page,total,limit
    }
    return (
      <AdminTableComponent
        header={header}
        body={body}
        pagination={pagination}
        filter={filter}
        updateCriteria={this.updateCriteria}
        isFetching={isLoading}
      />
    )
  }

  render(){
    const {isLoading} = this.state;
    return (
      <div className="portals-list">
        {isLoading && (<LoaderComponent/>)}
        <h3>Dostępne pakiety dostępu:</h3>
        {this.renderUserAccessTypes()}
      </div>
    );
  }
}
