import React from 'react';
import "./PaymentsComponent.scss";
import {Table,Button} from 'react-bootstrap';
import {fetchData} from '../../services';
import AdminTableComponent from '../helpers/AdminTableComponent';
import LoaderComponent from '../../Loader/LoaderComponent';
const {REACT_APP_BACKEND_URL} = process.env;

export default class PaymentsComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      payments: [],
      page: 1,
      limit: 10,
      total: 0,
      orderBy: 'id',
      filter: '',
      isFetching: false,
    }
    this.fetchPayments = this.fetchPayments.bind(this);
    this.updateCriteria = this.updateCriteria.bind(this);
  }

  componentDidMount(){
    this.fetchPayments();
  }

  fetchPayments(){
    const {page,limit,orderBy,filter} = this.state;
    this.setState({
      isFetching: true,
    });
    fetchData(`${REACT_APP_BACKEND_URL}/api/payment/all/${page}/${limit}/${orderBy}/${filter}`)
      .then(data=>this.setState({
        payments:data.payments,
        total:data.total,
      }))
      .catch(err=>console.warn(err))
      .finally(()=>this.setState({
        isFetching: false,
      }))
  }

  removePayments(paymentId){
    if(window.confirm("Usunąć wybraną płatność?")){
      if(window.confirm("Płatność powinno się usuwać bardzo ostrożnie, np. z przyczyn testowych. Czy usunąć?")){
        fetchData(`${REACT_APP_BACKEND_URL}/api/payment/remove/${paymentId}`,'DELETE')
          .then(data=>!!data.success && this.fetchPayments())
          .catch(err=>console.warn(err));
      }
    }
  }

  updateCriteria(name,value){
    this.setState({
      [name]: value
    },()=>{
      this.fetchPayments();
    })
  }

  renderSinglePayment(p,k){
    return (
      <>
        <td>{p.id}</td>
        <td>{p.id}<Button variant="warning" onClick={()=>this.removePayments(p.id)}>Usuń</Button></td>
        <td>{p.uid}</td>
        <td>{p.owner}</td>
        <td>{p.initialTime}</td>
        <td>{p.accessType}</td>
        <td>{p.p24Description}</td>
        <td>{p.p24ConfirmationTime}</td>
      </>
    )
  }

  renderPayments(){
    const {page,total,limit,filter,isFetching} = this.state;
    const header = [
      (<span>LP</span>),
      (<span>Id</span>),
      (<span>Uid</span>),
      (<span>Właściciel</span>),
      (<span>Czas rozpoczęcia</span>),
      (<span>Typ pakietu</span>),
      (<span>Opis przekazany do P24</span>),
      (<span>Czas potwierdzenia z P24</span>),
    ];
    const body = this.state.payments.map((u,k) => this.renderSinglePayment(u,k));
    const pagination = {
      page,total,limit
    }
    return (
      <AdminTableComponent
        header={header}
        body={body}
        pagination={pagination}
        filter={filter}
        updateCriteria={this.updateCriteria}
        isFetching={isFetching}
      />
    )
  }

  render(){
    const {isFetching} = this.state;
    return (
      <div className="payments-list">
      {isFetching && (<LoaderComponent/>)}
        <h3>Płatności:</h3>
        {this.renderPayments()}
      </div>
    )
  }
}
