import React from 'react';
import "./PortalsComponent.scss";
import {Table,Button,Form} from 'react-bootstrap';
import {fetchData} from '../../services';
import AdminTableComponent from '../helpers/AdminTableComponent';
import LoaderComponent from '../../Loader/LoaderComponent';
const {REACT_APP_BACKEND_URL,REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class PortalsComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      portals: [],
      page: 1,
      limit: 10,
      total: 0,
      orderBy: 'orderNumber',
      filter: '',
      isLoading: false,
    }
    this.fetchPortals = this.fetchPortals.bind(this);
    this.renderPortals = this.renderPortals.bind(this);
    this.updateOrderNumber = this.updateOrderNumber.bind(this);
    this.removePortal = this.removePortal.bind(this);
    this.updateCriteria = this.updateCriteria.bind(this);
  }

  componentDidMount(){
    this.fetchPortals();
  }

  fetchPortals(){
    const {page,limit,orderBy,filter} = this.state;
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_URL}/api/portals/${page}/${limit}/${orderBy}/${filter}`)
      .then(data=>this.setState({
        portals:data.portals,
        total:data.total,
      }))
      .catch(err=>console.warn(err))
      .finally(()=>this.setState({isLoading:false}));
  }

  removePortal(id){
    if(window.confirm("Czy usunąć?")){
      this.setState({isLoading:true});
      fetchData(`${REACT_APP_BACKEND_URL}/api/portals/${id}`,'DELETE')
        .then(data=>this.fetchPortals())
        .catch(err=>console.warn(err))
        .finally(()=>this.setState({isLoading:false}));
    }
  }

  updateOrderNumber(ev,portalId){
    const {target:{value}} = ev;
    const data = {'orderNumber':value};
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_URL}/api/portals/${portalId}/update-on`,'PATCH',data)
      .then(data=>this.fetchPortals())
      .catch(err=>console.warn(err.errorMessage))
      .finally(()=>this.setState({isLoading:false}));
  }

  updateCriteria(name,value){
    this.setState({
      [name]: value
    },()=>{
      this.fetchPortals();
    })
  }

  renderSinglePortal(p,k){
    const {isLoading} = this.state;
    return (
      <>
        <td>
          {k+1}
          <Button variant="danger" onClick={()=>this.removePortal(p.id)}>Usuń</Button>
        </td>
        <td>
          <Form.Control
            type="number"
            onChange={(ev)=>this.updateOrderNumber(ev,p.id)}
            value={p.orderNumber || 1}
            disabled={isLoading}
          />
        </td>
        <td>{p.name}</td>
        <td className="portal-logo">
          <img src={`${REACT_APP_BACKEND_OPEN_URL}/api/portals/logo/${p.id}`} alt="Logo" />
        </td>
        <td>
          {p.relationsNumber}
        </td>
      </>
    )
  }

  renderPortals(){
    const {page,total,limit,filter,isLoading} = this.state;
    const header = [
      (<span>Id</span>),
      (<span>Kolejność wyświetlania</span>),
      (<span>Nazwa</span>),
      (<span>Logo</span>),
      (<span>Ilość relacji</span>),
    ];
    const body = this.state.portals.map((u,k) => this.renderSinglePortal(u,k));
    const pagination = {
      page,total,limit
    }
    return (
      <AdminTableComponent
        header={header}
        body={body}
        pagination={pagination}
        filter={filter}
        updateCriteria={this.updateCriteria}
        isFetching={isLoading}
      />
    )
  }

  render(){
    const {isLoading} = this.state;
    return (
      <div className="portals-list">
        {isLoading && (<LoaderComponent/>)}
        <h3>Dostępne portale:</h3>
        {this.renderPortals()}
      </div>
    );
  }
}
