import React from 'react';
import './StaticPageComponent.scss';
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import {fetchData} from '../services';
import FrontEndHeaderComponent from '../FrontEndHeaderComponent';
import FrontEndFooterComponent from '../FrontEndFooterComponent';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

function withParams(Component){
  return props => <Component {...props} params={useParams()}/>;
}

class StaticPageComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isFetching: false,
      content: '',
    }
    this.fetchContent = this.fetchContent.bind(this);
  }

  componentDidMount(){
    this.fetchContent();
  }

  componentDidUpdate(prevProps){
    if(prevProps.params.id !== this.props.params.id){
      this.fetchContent();
    }
  }

  fetchContent(){
    const {params:{id}} = this.props;
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/static-pages/content/${id}`)
      .then(data=>{
        if(data) this.setState({
          content: data.content,
        });
      })
      .finally(()=>this.setState({isLoading:false}));
  }

  render(){
    const {content} = this.state;
    return (
      <main className="front-end">
        <FrontEndHeaderComponent />
        <section dangerouslySetInnerHTML={{__html: content}}/>
        <FrontEndFooterComponent />
      </main>
    )
  }
}

export default withParams(StaticPageComponent);
