import React from 'react';
import "./BlogEntriesComponent.scss";
import {Table,Button} from 'react-bootstrap';
import {fetchData} from '../../services';
import AdminTableComponent from '../helpers/AdminTableComponent';
import LoaderComponent from '../../Loader/LoaderComponent';
const {REACT_APP_BACKEND_URL} = process.env;

export default class BlogEntriesComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      entries: [],
      page: 1,
      limit: 10,
      total: 0,
      orderBy: 'id',
      filter: '',
      isFetching: false,
      denyReason: null,
    }
    this.fetchEntries = this.fetchEntries.bind(this);
    this.fetchEntriesByStatus = this.fetchEntriesByStatus.bind(this);
    this.changeEntryStatus = this.changeEntryStatus.bind(this);
    this.updateCriteria = this.updateCriteria.bind(this);
  }

  componentDidMount(){
    this.fetchEntries();
  }

  componentDidUpdate(prevProps){
    if(prevProps.entriesType !== this.props.entriesType){
      this.fetchEntries();
    }
  }

  fetchEntries(){
    const {entriesType} = this.props;
    switch(entriesType){
      case('to-decide'): this.fetchEntriesByStatus('to-decide'); break;
      case('approved'): this.fetchEntriesByStatus('approved'); break;
      case('denied'): this.fetchEntriesByStatus('denied'); break;
    }
  }

  fetchEntriesByStatus(status){
    const {page,limit,orderBy,filter} = this.state;
    this.setState({isFetching: true});
    fetchData(`${REACT_APP_BACKEND_URL}/api/blog-entry/${status}/${page}/${limit}/${orderBy}/${filter}`)
      .then(data=>this.setState({
        entries:data.entries,
        total:data.total,
      }))
      .catch(err=>console.warn(err))
      .finally(()=>this.setState({isFetching:false}));
  }

  updateCriteria(name,value){
    this.setState({
      [name]: value
    },()=>{
      this.fetchEntries();
    })
  }

  changeEntryStatus(id,status){
    let message = 'Czy na pewno ';
    message+= 'approve' === status ? 'zaakceptować' : 'odrzucić';
    message+= ' wpis?';
    let denyReason = null;
    if(window.confirm(message)){
      if('decline'===status) denyReason = window.prompt('Podaj przyczynę odrzucenia:');
      const data = {
        denyReason,
      }
      fetchData(`${REACT_APP_BACKEND_URL}/api/blog-entry/${id}/${status}`,'PATCH',data)
        .then(data=>!!data.success && this.fetchEntries())
        .catch(err=>console.warn(err));
    }
  }

  removeEntry(id){
    if(window.confirm('Usunąć?')){
      this.setState({isFetching:true})
      fetchData(`${REACT_APP_BACKEND_URL}/api/blog-entry/${id}`,'DELETE')
        .then(data=>!!data.success && this.fetchEntries())
        .catch(err=>console.warn(err))
        .finally(()=>this.setState({isFetching:false}));
    }
  }

  renderSingleEntry(e,k){
    const {entriesType} = this.props;
    return (
      <>
        <td>{e.id}</td>
        <td>{e.title}</td>
        <td>{e.content}</td>
        <td><ul>{e.categories.map((c,k) => (<li key={`e${e.id}c${k}`}>{c.name}</li>))}</ul></td>
        <td>{e.decisionTime}</td>
        <td>{e.decisionMaker}</td>
        <td>{e.denyReason}</td>
        <td>
          {'approved'!==entriesType && (<Button variant="success" onClick={()=>this.changeEntryStatus(e.id,'approve')}>Zaakceptuj</Button>)}
          <br/>
          {'denied'!==entriesType && (<Button variant="warning" onClick={()=>this.changeEntryStatus(e.id,'decline')}>Odrzuć</Button>)}
          <br/>
          <Button variant="danger" onClick={()=>this.removeEntry(e.id)}>Usuń</Button>
        </td>
      </>
    )
  }

  renderEntries(){
    const {page,total,limit,filter,isFetching} = this.state;
    const header = [
      (<span>ID</span>),
      (<span>Tytuł</span>),
      (<span>Treść</span>),
      (<span>Kategorie</span>),
      (<span>Data akceptacji/odrzucenia</span>),
      (<span>Użytkownik podejmujący decyzję</span>),
      (<span>Przyczyna odrzucenia</span>),
      (<span>Decyzje</span>),
    ];
    const body = this.state.entries.map((u,k) => this.renderSingleEntry(u,k));
    const pagination = {
      page,total,limit
    }
    return (
      <AdminTableComponent
        header={header}
        body={body}
        pagination={pagination}
        filter={filter}
        updateCriteria={this.updateCriteria}
        isFetching={isFetching}
      />
    )
  }

  render(){
    const {entries,isFetching} = this.state;
    const {entriesType} = this.props;
    let label = "Wpisy ";
    switch(entriesType){
      case("to-decide"):
        label+="do zaakceptowania lub odrzucenia:";break;
      case("approved"):
        label+="zaakceptowane:";break;
      case("denied"):
        label+="odrzucone:";break;
    }
    return (
        <div className="entries-list">
          {isFetching && (<LoaderComponent/>)}
          <h3>{label}</h3>
          {this.renderEntries()}
        </div>
    );
  }
}
