import React from 'react';
import "./PageSettingsComponent.scss";
import {Table,Button, Form} from 'react-bootstrap';
import {fetchData} from '../../services';
import AdminTableComponent from '../helpers/AdminTableComponent';
import LoaderComponent from '../../Loader/LoaderComponent';
const {REACT_APP_BACKEND_URL} = process.env;

export default class PageSettingsComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      settings: [],
      newSettingName: '',
      newSettingValue: '',
      page: 1,
      limit: 10,
      total: 0,
      orderBy: 'id',
      filter: '',
      isFetching: false,
    }
    this.fetchSettings = this.fetchSettings.bind(this);
    this.addNewSetting = this.addNewSetting.bind(this);
    this.updateCriteria = this.updateCriteria.bind(this);
  }

  componentDidMount(){
    this.fetchSettings();
  }

  fetchSettings(){
    const {page,limit,orderBy,filter} = this.state;
    this.setState({
      isFetching: true,
    });
    fetchData(`${REACT_APP_BACKEND_URL}/api/settings/all/${page}/${limit}/${orderBy}/${filter}`)
      .then(data=>this.setState({
        settings:data.settings,
        total:data.total,
      }))
      .catch(err=>console.warn(err))
      .finally(()=>this.setState({
        isFetching: false,
      }))
  }

  removeSetting(settingId){
    if(window.confirm("Usunąć wybrane ustawienie?")){
      if(window.confirm("Ustawienia powinno się usuwać bardzo ostrożnie, np. z przyczyn testowych. Czy usunąć?")){
        fetchData(`${REACT_APP_BACKEND_URL}/api/setting/remove/${settingId}`,'DELETE')
          .then(data=>!!data.success && this.fetchSettings())
          .catch(err=>console.warn(err));
      }
    }
  }

  addNewSetting(ev){
    ev.preventDefault();
    const {newSettingName,newSettingValue} = this.state;
    const data = {
      settingName:newSettingName,
      settingValue:newSettingValue,
    };
    fetchData(`${REACT_APP_BACKEND_URL}/api/setting/new`,'POST',data)
      .then(data=>!!data.success && this.fetchSettings())
      .catch(err=>console.warn(err));
  }

  updateCriteria(name,value){
    this.setState({
      [name]: value
    },()=>{
      this.fetchSettings();
    })
  }

  renderSingleSetting(s,k){
    return (
      <>
        <td>{s.id}<Button variant="warning" onClick={()=>this.removeSetting(s.id)}>Usuń</Button></td>
        <td>{s.settingName}</td>
        <td>{s.settingValue}</td>
      </>
    )
  }

  renderSettings(){
    const {page,total,limit,filter,isFetching} = this.state;
    const header = [
      (<span>Id</span>),
      (<span>Nazwa</span>),
      (<span>Wartość</span>),
    ];
    const body = this.state.settings.map((u,k) => this.renderSingleSetting(u,k));
    const pagination = {
      page,total,limit
    }
    return (
      <AdminTableComponent
        header={header}
        body={body}
        pagination={pagination}
        filter={filter}
        updateCriteria={this.updateCriteria}
        isFetching={isFetching}
      />
    )
  }

  updateItem(item,value){
    this.setState({
      [item]:value,
    })
  }

  renderAddSettingPanel(){
    const {newSettingName,newSettingValue} = this.state;
    return (
      <Form onSubmit={this.addNewSetting}>
        <Form.Group>
          <Form.Label>Nazwa ustawienia</Form.Label>
          <Form.Control
            type="Text"
            value={newSettingName}
            placeholder="Nazwa ustawienia"
            onChange={(ev)=>this.updateItem('newSettingName',ev.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Wartość ustawienia</Form.Label>
          <Form.Control
            as="textarea"
            value={newSettingValue}
            onChange={ev=>this.updateItem('newSettingValue',ev.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Dodaj
        </Button>
      </Form>
    )
  }

  render(){
    const {isFetching} = this.state;
    return (
      <div className="settings-list">
        {isFetching && (<LoaderComponent/>)}
        <h3>Ustawienia strony:</h3>
        {this.renderSettings()}
        {this.renderAddSettingPanel()}
        <ul>
          <li>Wartości dostępne dla social media: 'facebook','instagram','youtube','snapchat','pinterest','linkedin','tiktok'</li>
          <li>Aby dodać napis na stronie głównej należy użyć klucza zawierającego wyrażenie "label"</li>
          <li>Aby ustawić czerwoną wersję należy dodać rekord z nazwą 'isRed' i wartością 1</li>
        </ul>
      </div>
    )
  }
}
