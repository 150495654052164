import React from 'react';
import "./FindOutComponent.scss";
import {Outlet,Link,Navigate} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import LoaderComponent from './Loader/LoaderComponent';
import { fetchData } from './services';

const { REACT_APP_BACKEND_OPEN_URL } = process.env;

export default class FindOutComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      cateogries: [],
      isLoading: false,
    }
    this.fetchCategories = this.fetchCategories.bind(this);
  }

  componentDidMount(){
    this.fetchCategories();
  }

  fetchCategories(){
    this.setState({
      isLoading: true,
    })
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/blog-category/all`)
      .then(data=>data.success && this.setState({
        categories: data.categories,
      }))
      .catch(err => console.warn(err))
      .finally(()=>this.setState({isLoading:false}));
  }

  renderCategory(c,l){
    return (
      <li key={`link-${l}`}><Link to={`/historie/c/${c.id}/${c.queryString}`}>{c.name}</Link></li>
    );
  }

  renderCategories(){
    const {categories} = this.state;
    return (
      <ul>
       {categories?.map((c,l) => this.renderCategory(c,l))}
      </ul>
    );
  }

  render() {
    const {isLoading} = this.state;
    const {isLoggedIn,isAdmin,user} = this.props;
    return (
      <main>
        {isLoading ? (<LoaderComponent />) : (
          <>
            {isAdmin && (<Navigate to="/admin" redirect={true} />)}
            <aside className="left-aside">Miejsce na Twoją reklamę</aside>
            <section className="main-section">
              {<Outlet/>}
            </section>
            <aside className="right-aside">
              <h5>Historie randkowe</h5>
              {this.renderCategories()}
              {isLoggedIn && (<Link to="/historie/nowa" className="add-new-story"><Button variant="danger">Dodaj nową historię</Button></Link>)}
            </aside>
          </>
        )}
      </main>
    );
  }
}
