import React from 'react';
import {Table,Button,Form} from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {fetchData} from '../../services';
import AdminTableComponent from '../helpers/AdminTableComponent';
import LoaderComponent from '../../Loader/LoaderComponent';
const {REACT_APP_BACKEND_URL} = process.env;

export default class BlogCategoriesComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      categories: [],
      newCategoryName: '',
      page: 1,
      limit: 10,
      total: 0,
      orderBy: 'categoryOrder',
      filter: '',
      isFetching: false,
    }
    this.fetchCategories = this.fetchCategories.bind(this);
    this.addNewCategory = this.addNewCategory.bind(this);
    this.updateOrderNumber = this.updateOrderNumber.bind(this);
    this.removeCategory = this.removeCategory.bind(this);
    this.switchCategoryActiveness = this.switchCategoryActiveness.bind(this);
    this.updateCriteria = this.updateCriteria.bind(this);
  }

  componentDidMount(){
    this.fetchCategories();
  }

  fetchCategories(){
    const {page,limit,orderBy,filter} = this.state;
    this.setState({
      isFetching: true,
    });
    fetchData(`${REACT_APP_BACKEND_URL}/api/blog-category/all/${page}/${limit}/${orderBy}/${filter}`)
      .then(data=>this.setState({
        categories:data.categories,
        total:data.total,
      }))
      .catch(err=>console.warn(err))
      .finally(()=>this.setState({
        isFetching: false,
      }))
  }

  switchCategoryActiveness(id){
    fetchData(`${REACT_APP_BACKEND_URL}/api/blog-category/switch-active/${id}`,'PATCH')
      .then(data=>!!data.success && this.fetchCategories())
      .catch(err=>console.warn(err));
  }

  removeCategory(id){
    if(window.confirm("Usunąć wybraną kategorię?")){
      if(window.confirm("Kategorie powinno się usuwać bardzo ostrożnie, np. z przyczyn testowych. Czy usunąć?")){
        fetchData(`${REACT_APP_BACKEND_URL}/api/blog-categories/remove/${id}`,'DELETE')
          .then(data=>!!data.success && this.fetchCategories())
          .catch(err=>console.warn(err));
      }
    }
  }

  updateOrderNumber(ev,id){
    const data = {
      categoryOrder: parseInt(ev.target.value),
    }
    fetchData(`${REACT_APP_BACKEND_URL}/api/blog-category/change-order/${id}`,'PATCH',data)
      .then(data=>!!data.success && this.fetchCategories())
      .catch(err=>console.warn(err));
  }

  addNewCategory(ev){
    ev.preventDefault();
    const {newCategoryName,newCategoryOrderNumber} = this.state;
    if(newCategoryName){
      const data = {
        name: newCategoryName,
        orderNumber: newCategoryOrderNumber,
      }
      fetchData(`${REACT_APP_BACKEND_URL}/api/blog-category/new`,'POST',data)
        .then(data=>!!data.success && this.fetchCategories())
        .catch(err=>console.warn(err));
    }
  }

  updateCriteria(name,value){
    this.setState({
      [name]: value
    },()=>{
      this.fetchCategories();
    })
  }

  renderSingleCategory(c,k){
    return (
      <>
        <td>{c.id}</td>
        <td><Form.Control
          type="number"
          onChange={(ev)=>this.updateOrderNumber(ev,c.id)}
          value={c.categoryOrder || 1}
        /></td>
        <td>{c.id}<Button variant="warning" onClick={()=>this.removeCategory(c.id)}>Usuń</Button></td>
        <td>{c.name}</td>
        <td>{c.created}</td>
        <td>{c.owner}</td>
        <td>
          <BootstrapSwitchButton
            checked={c.isActive}
            size="xs"
            onstyle="danger"
            onlabel='Aktywna'
            offlabel='Nieaktywna'
            onChange={()=>this.switchCategoryActiveness(c.id)}
          />
        </td>
      </>
    )
  }

  renderCategories(){
    const {page,total,limit,filter,isFetching} = this.state;
    const header = [
      (<span>LP</span>),
      (<span>Kolejność</span>),
      (<span>Id</span>),
      (<span>Nazwa</span>),
      (<span>Utworzona</span>),
      (<span>Twórca</span>),
      (<span>Czy aktywna</span>),
    ];
    const body = this.state.categories.map((u,k) => this.renderSingleCategory(u,k));
    const pagination = {
      page,total,limit
    }
    return (
      <AdminTableComponent
        header={header}
        body={body}
        pagination={pagination}
        filter={filter}
        updateCriteria={this.updateCriteria}
        isFetching={isFetching}
      />
    )
  }

  updateItem(item,value){
    this.setState({
      [item]:value,
    })
  }

  renderAddCategoryPanel(){
    const {newCategoryName,newCategoryOrderNumber} = this.state;
    return (
      <Form onSubmit={this.addNewCategory}>
        <Form.Group>
          <Form.Label>Nazwa nowej kategorii</Form.Label>
          <Form.Control
            type="Text"
            value={newCategoryName}
            placeholder="Nazwa kategorii"
            onChange={(ev)=>this.updateItem('newCategoryName',ev.target.value)}
          />
        </Form.Group>
        <Button variant="warning" type="submit">
          Utwórz
        </Button>
      </Form>
    )
  }

  render(){
    return (
      <div className="settings-list">
        <h3>Kategorie wpisów na blogu:</h3>
        {this.renderCategories()}
        {this.renderAddCategoryPanel()}
      </div>
    )
  }
}
