import React from 'react';
import "./FrontEndFooterComponent.scss";
import { Link } from 'react-router-dom';
import GoogleStore from './assets/store-google.png';
import AppStore from './assets/store-ios.png';
import GoogleStoreW from './assets/store-google_w.png';
import AppStoreW from './assets/store-ios_w.png';
import { fetchData } from './services';

const { REACT_APP_BACKEND_OPEN_URL } = process.env;

export default class FrontEndFooterCompnent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      urls: [],
    };
    this.fetchMenu = this.fetchMenu.bind(this);
  }

  componentDidMount(){
    this.fetchMenu();
  }

  fetchMenu(){
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/static-pages/bottom`)
      .then(data=>this.setState({
        urls: data.urls,
      }))
      .catch(err=>console.warn(err));
  }

  render(){
    const {isRed} = this.props;
    return (
      <footer>
        <h5>Do pobrania</h5>
        <div className="mobile-stores">
          {isRed ? (<img src={GoogleStoreW} alt="Google Store" />):(<img src={GoogleStore} alt="Google Store" />)}
          {isRed ? (<img src={AppStoreW} alt="App Store" />):(<img src={AppStore} alt="App Store" />)}
        </div>
        <nav>
          <ul>
            {this.state.urls.map((u,n) => (<li key={`bottom-url${n}`}><Link to={`/${u.id}/${u.query}`}>{u.name}</Link></li>))}
          </ul>
        </nav>
      </footer>
    )
  }
}
